'use client';

import { Block } from '@/components/Block';
import Image from 'next/image';
import Link from 'next/link';
import Marquee from 'react-fast-marquee';

const images = [
	{
		url: '/logos/ascension-logo.png',
		alt: 'Ascension Health',
		href: 'https://healthcare.ascension.org/',
		height: 100,
		width: 250,
	},
	{
		url: '/logos/banner-health-logo.png',
		alt: 'Banner Health',
		href: 'https://www.bannerhealth.com/',
		height: 100,
		width: 250,
	},
	{
		url: '/logos/ena-logo.png',
		alt: 'Emergency Nurses Association',
		href: 'https://www.ena.org/',
		height: 100,
		width: 250,
	},
	{
		url: '/logos/honor-health-logo.png',
		alt: 'Honor Health',
		href: 'https://www.honorhealth.com/',
		height: 100,
		width: 250,
	},
	{
		url: '/logos/loma-linda-university-health.png',
		alt: 'Loma Linda University Health',
		href: 'https://lluh.org/',
		height: 100,
		width: 250,
	},
	{
		url: '/logos/ascension-logo.png',
		alt: 'Ascension Health',
		href: 'https://healthcare.ascension.org/',
		height: 100,
		width: 250,
	},
	{
		url: '/logos/banner-health-logo.png',
		alt: 'Banner Health',
		href: 'https://www.bannerhealth.com/',
		height: 100,
		width: 250,
	},
	{
		url: '/logos/ena-logo.png',
		alt: 'Emergency Nurses Association',
		href: 'https://www.ena.org/',
		height: 100,
		width: 250,
	},
	{
		url: '/logos/honor-health-logo.png',
		alt: 'Honor Health',
		href: 'https://www.honorhealth.com/',
		height: 100,
		width: 250,
	},
	{
		url: '/logos/loma-linda-university-health.png',
		alt: 'Loma Linda University Health',
		href: 'https://lluh.org/',
		height: 100,
		width: 250,
	},
];

const LogoCloud = () => {
	return (
		<Block id='logos' className='-mt-24 lg:-mt-36 overflow-x-hidden'>
			<div className='animate-in fade-in flex flex-col lg:flex-row gap-x-4 lg:gap-x-16 lg:justify-between items-center relative'>
				<div className='left-0 w-1/6 bg h-full bg-gradient-to-r from-background to-background/0 z-10 absolute pointer-events-none  overflow-hidden' />
				<Marquee
					gradient={false}
					speed={40}
					className='flex items-center min-h-[40px] lg:min-h-[100px] my-8 max-w-7xl'>
					{images.map((image, index) => {
						return (
							<Link
								target='_blank'
								referrerPolicy='no-referrer'
								href={image.href}
								key={`${index}-${image.alt}`}>
								<Image
									className='-mx-8 scale-[0.9] lg:scale-100 lg:mx-12 opacity-50 hover:opacity-100 transition-opacity duration-300 ease-in-out cursor-pointer'
									key={index}
									src={image.url}
									alt={image.alt}
									height={image.height}
									width={image.width}
									priority
								/>
							</Link>
						);
					})}
				</Marquee>
				<div className='w-1/6 h-full bg-gradient-to-r from-background/0 to-background absolute right-0 z-10 pointer-events-none overflow-hidden' />
			</div>
		</Block>
	);
};

export default LogoCloud;
